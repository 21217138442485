import React from 'react'
import { Link } from "react-router-dom"

function Footer() {

    

    return (
        <div>
        <footer className="main-footer">
            <hr />
            <strong>© 2025 </strong>
            JUDO klub Levice
            <div className="float-right d-none d-sm-inline-block">
            <strong>            
            <MyMenuLink
              activeOnlyWhenExact={true}
              to="/zoznam-prihlasenych"
              label="Prihlásenie"
            />
            </strong>
            </div>
        </footer>
        </div>
    )
}

export default Footer


function MyMenuLink({ label, to, favIcon, activeOnlyWhenExact }) {
  // let match = useRouteMatch({
  //   path: to,
  //   exact: activeOnlyWhenExact,
  //   favIcon: favIcon
  // });

  return (
    <Link to={to} className="nav-link">
      {label}
      {/* <span className="badge badge-info right">2</span> */}
    </Link>
    //   <div className={match ? "active" : ""}>
    //     {match && "> "}
    //     <Link to={to}>{label}</Link>
    //   </div>
  )
}

